@import "../../../public/mixins/mixins.scss";

.footerWrapper {
  background: #f6f6f6;
  margin-top: 50px;
  width: 100%;

  .footerContainer {
    max-width: 1440px;
    margin: auto;
    padding-top: 52px;
    padding-left: 52px;
    padding-right: 52px;
    @include flexContainer(row, space-between, unset, 30px, 30px, wrap);
    @include mobile() {
      flex-direction: column;
      padding: 20px;
    }

    .infoContainer {
      .title {
        @include font(16px, 500, #4a536a, 0.64px);
      }
      .listValues {
        @include font(13px, 500, #666161, 0.52px, 26px);
        list-style-type: none;
      }
      .link {
        text-decoration: none;
      }
    }
  }
  .company {
    flex: 1;
    @include flexContainer(row, space-between, unset, 30px, 30px, wrap);
    padding-top: 52px;
    padding-bottom: 20px;
    padding-left: 52px;
    padding-right: 52px;
    @include mobile() {
      flex-direction: column;
      padding: 20px;
      display: flex !important;
      align-items: center !important;
    }
    .companyContainer {
      @include mobile() {
        text-align: center;
      }

      .name {
        @include font(14px, 500, #4a536a, 0.64px);
      }
      .copy {
        @include font(14px, 500, #4a536a, 0.64px);
        justify-content: flex-end;
      }
    }
  }
}

.socialIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.socialIcon {
  color: #333; // Adjust color as needed
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #007bff; // Adjust hover color as needed
  }
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}

.contact-link {
  @include font(13px, 500, #666161, 0.52px, 26px);
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #007bff;
  }
}
