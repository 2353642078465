@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

:root {
  --background: 203 100% 100%;
  --foreground: 203 5% 10%;
  --card: 203 50% 100%;
  --card-foreground: 203 5% 15%;
  --popover: 203 100% 100%;
  --popover-foreground: 203 100% 10%;
  --primary: 203 100% 34.3%;
  --primary-foreground: 0 0% 100%;
  --secondary: 203 30% 90%;
  --secondary-foreground: 0 0% 0%;
  --muted: 165 30% 95%;
  --muted-foreground: 203 5% 40%;
  --accent: 165 30% 90%;
  --accent-foreground: 203 5% 15%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 203 5% 100%;
  --border: 203 30% 82%;
  --input: 203 30% 50%;
  --ring: 203 100% 34.3%;
  --radius: 25px;
}
.dark {
  --background: 203 50% 10%;
  --foreground: 203 5% 100%;
  --card: 203 50% 10%;
  --card-foreground: 203 5% 100%;
  --popover: 203 50% 5%;
  --popover-foreground: 203 5% 100%;
  --primary: 203 100% 34.3%;
  --primary-foreground: 0 0% 100%;
  --secondary: 203 30% 20%;
  --secondary-foreground: 0 0% 100%;
  --muted: 165 30% 25%;
  --muted-foreground: 203 5% 65%;
  --accent: 165 30% 25%;
  --accent-foreground: 203 5% 95%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 203 5% 100%;
  --border: 203 30% 50%;
  --input: 203 30% 50%;
  --ring: 203 100% 34.3%;
  --radius: 1rem;
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.filled-icons {
  display: flex;
  flex-direction: row;
}

.style-module_starRatingWrap__q-lJC {
  rotate: 90deg;
}

.react-international-phone-input {
  overflow: visible;
  height: var(--react-international-phone-height, 36px);
  box-sizing: border-box;
  padding: 0 8px;
  border: 1px solid var(--react-international-phone-border-color, gainsboro);
  border-radius: var(--react-international-phone-border-radius, 4px);
  margin: 0;
  background-color: var(--react-international-phone-background-color, white);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: var(--react-international-phone-text-color, #222);
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;
  font-size: var(--react-international-phone-font-size, 13px);
  border: none !important;
}

.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  border-radius: var(--react-international-phone-border-radius, 4px);
  margin-right: -1px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: none;
}

.counryInfo {
  display: flex;
  flex-direction: row;
  justify-content: unset;
  align-items: center;
  row-gap: 0;
  column-gap: 15px;
  flex-wrap: nowrap;
  font-size: 12px;
  font-weight: 400;
  color: #454f66;
  letter-spacing: 0.48px;
  line-height: normal;
  font-style: normal;
  border-radius: 19px;
  border: 1px dashed #e2e5ed;
  padding: 6px;
  min-width: 115px;
  margin: 0 3px;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid black; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

ul {
  list-style-type: disc; /* Use disc for bullet points */
}

ul li {
  margin-left: 20px; /* Adjust as needed for indentation */
}

ol,
ul,
menu {
  list-style: disc;
  /* margin: 20px 0px; */
  padding: auto;
}

b,
strong {
  font-weight: bolder;
  margin-top: 20px;
}

.ck-editor__main h2 {
  font-size: 1.68em;
  margin-bottom: 0.4em;
  font-weight: 400;

  // color: red;
}

iframe {
  // position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  min-height: 400px;
  min-width: 400px;
}
ol,
li {
  counter-increment: list-counter;
  margin-bottom: 5px;
  margin-left: 20px;
}

// wikipedia style
/* global.scss */

/* Import Normalize.css or include a CSS reset */
@import "normalize.css";

/* Base typography */
body {
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #202122;
  background-color: #fff;
  margin: 0;
  padding: 0;
}

ol li {
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;
}

a {
  color: #0645ad;
  text-decoration: none;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;
}

/* Layout */
.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;
  color: #202122;
  margin-top: 1.6em;
  margin-bottom: 0.8em;
  line-height: 1.2;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.25em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 0.875em;
}

/* Paragraphs */
p {
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;

  // margin-top: auto;
  // margin-bottom: auto;
}

/* Lists */
ul,
ol {
  margin: 0 0 1em 2em;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;
}

li {
  margin: 0.5em 0;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;
}

/* Tables */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;
}

th,
td {
  border: 1px solid #a2a9b1;
  padding: 0.5em;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;
}

th {
  background-color: #f8f9fa;
  text-align: left;
}

caption {
  font-weight: bold;
  margin-bottom: 0.5em;
  text-align: left;
}

/* Blockquotes */
blockquote {
  border-left: 4px solid #d0d0d0;
  margin: 0 0 1em;
  padding-left: 1em;
  color: #6a737d;
  background-color: #f8f9fa;
}

/* Code */
code,
pre {
  background-color: #f8f9fa;
  padding: 0.2em 0.4em;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;
}

pre {
  padding: 1em;
  overflow: auto;
}

@media (max-width: 600px) {
  .container {
    padding: 0 0.5rem;
  }

  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.4em;
  }

  h3 {
    font-size: 1.3em;
  }

  h4 {
    font-size: 1.2em;
  }

  h5 {
    font-size: 1.1em;
  }

  h6 {
    font-size: 1em;
  }
}

/* Lists */
ul,
ol {
  margin: 0 0 1em 2em;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;
}

li {
  margin: 0.5em 0;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;
}

/* Ordered List Style */
ol {
  list-style-type: decimal;
  margin-left: 20px !important;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;
}

ol li::marker {
  content: counter(list-item) ". ";
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif;
}

.dropdown {
  position: relative; // or absolute depending on your layout
  z-index: 10; // Ensure this is higher than the main form
}

/* src/components/LoadingSpinner/LoadingSpinner.module.css */
.spinner {
  border-top-color: #3498db;
  border-bottom-color: #3498db;
  -webkit-animation: spinner 0.8s linear infinite;
  animation: spinner 0.8s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
